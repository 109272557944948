import { SessionTimerService } from 'src/app/core/services/session-timer.service';
import { ActiveSessionService } from './../../../../core/services/active-session.service';
import { UserService } from './../../../../core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

let passwordMatchValidator: any = function (fg: FormGroup) {
  // if (fg.get('password')?.value === fg.get('confirm_password')?.value) {
  //   return null;
  // } else {
  //   fg.get('confirm_password')?.setErrors({ 'mismatch': true });
  // }
  // return { 'mismatch': true };

  const password = fg.get('password');
  const confirm_password = fg.get('confirm_password');
  if (password?.pristine || confirm_password?.pristine) {
    return null;
  }
  return password && confirm_password && password?.value != confirm_password?.value
    ? { mismatch: true }
    : null;
};

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit {
  changePasswordForm: FormGroup;
  user_id = '';
  type = '';
  via = '';
  loading = false;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public fb: FormBuilder,
    public userService: UserService,
    public toast: ToastrService,
    private formBuilder: FormBuilder,
    public activeSessionService: ActiveSessionService,
    private router: Router,
    public sessionTimerService: SessionTimerService
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      password: [null, [Validators.minLength(6), Validators.maxLength(30), this.noWhitespaceValidator]],
      confirm_password: [null, [Validators.minLength(6), Validators.maxLength(30), this.noWhitespaceValidator]]
    }, { validators: passwordMatchValidator } as AbstractControlOptions);

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  setPassword(isValid: any) {

    for (let c in this.changePasswordForm.controls) {
      this.changePasswordForm.controls[c].markAsTouched();
    }

    if (!isValid) { return; }
    this.loading = true;
    let sub;
    if (this.type && this.type === 'company_user') {
      if (this.via) {
        sub = this.userService.adminChangeCompanyUserPassword({
          id: this.user_id,
          password: this.changePasswordForm.get('password')?.value,
          confirm_password:
            this.changePasswordForm.get('confirm_password')?.value,
        });
      } else {
        sub = this.userService.changeCompanyUserPassword({
          id: this.user_id,
          password: this.changePasswordForm.get('password')?.value,
          confirm_password:
            this.changePasswordForm.get('confirm_password')?.value,
        });
      }
    } else {
      sub = this.userService.changePassword({
        id: this.user_id,
        password: this.changePasswordForm.get('password')?.value,
        confirm_password:
          this.changePasswordForm.get('confirm_password')?.value,
      });
    }

    sub.subscribe({
      next: (res: any) => {
        this.toast.success('Password changed successfully.', 'Success');
        this.loading = false;
        this.bsModalRef.hide();
        let localstorage_user = JSON.parse(
          localStorage.getItem('user') || '{}'
        );
        var account_type = localStorage.getItem('account_type');
        var prefix_name = (account_type && account_type === 'company_user') ? 'company' : 'admin';
        this.activeSessionService
          .deleteActiveSessionByUser(this.user_id, prefix_name, account_type)
          .subscribe({
            next: (res: any) => {
              this.sessionTimerService.stopSessionTimer();
            },
            error: (err: any) => {
            },
          });
        if (localstorage_user && localstorage_user.id == this.user_id) {
          localStorage.clear();
          if (account_type && account_type === 'company_user') {
            this.router.navigate(['/company/login']);
          } else {
            this.router.navigate(['/login']);
          }
        }
      },
      error: (err: any) => {
        this.loading = false;
        if (err.error === 'token_expired') {
          this.bsModalRef.hide();
        }

        if (err && err.error) {
          this.toast.error(err.error, 'Error');
        } else {
          this.toast.error('Something went wrong. Please try again later.', 'Error');
        }
      }
    });
  }
}
