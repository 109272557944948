import { ActiveSessionService } from './../../../core/services/active-session.service';
import { OsService } from './../../../core/services/os.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { admin_menu } from './../../admin-menu';
import { MenuService } from './../../../core/menu/menu.service';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from './../../../core/services/events.service';
import { UserService } from './../../../core/services/user.service';
import { Router } from '@angular/router';
import { AuthService } from './../../../core/services/auth/auth.service';
import { SettingsService } from './../../../core/settings/settings.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap, timer } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionTimerService } from 'src/app/core/services/session-timer.service';
import { customEmailValidator, noBlankSpaceValidator } from 'src/app/core/services/common-validators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  valForm: FormGroup;
  tokenForm: FormGroup;
  activeView = 'login';
  userId = Number;
  user_id = Number;

  invalidAttempts = 0;
  blocked = false;
  blockLeftMins = 0;
  show = false;
  ip: any;
  location: any;
  lastLoginTime: any;
  bsModalRef: BsModalRef;
  template: TemplateRef<any>;

  @ViewChild('inautofocus') searchElement: ElementRef;

  constructor(
    public settings: SettingsService,
    public Auth: AuthService,
    public route: Router,
    public userService: UserService,
    public router: Router,
    public eventsService: EventsService,
    private toasterService: ToastrService,
    public menuService: MenuService,
    public osService: OsService,
    public activeSessionService: ActiveSessionService,
    http: HttpClient,
    private modalService: BsModalService,
    private sessionTimerService: SessionTimerService
  ) {
    let tmp = localStorage.getItem('lastLoginTime');
    if (tmp) this.lastLoginTime = moment(parseInt(tmp)).format('M/D/Y hh:mma');

    this.valForm = new FormGroup({
      email: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+([.]*[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')])
      ),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          noBlankSpaceValidator,
          Validators.minLength(6),
          Validators.maxLength(20),
        ])
      ),
    });

    this.tokenForm = new FormGroup({
      token: new FormControl('', [
        Validators.minLength(6),
        Validators.maxLength(8),
      ]),
    });

    http.get('https://geolocation-db.com/json/').subscribe((locationRes) => {
      this.location = locationRes;
    });
  }

  checkBlocked() {
    if (this.invalidAttempts > 5) {
      return true;
    }
    if (localStorage.getItem('blocked')) {
      let blockedTime = moment(
        localStorage.getItem('blocked_time'),
        'YYYY-MM-DD HH:mm:ss'
      );
      let currTime = moment();
      let dur = moment.duration(currTime.diff(blockedTime));
      let minDiff = dur.asMinutes();
      if (minDiff <= 30) {
        this.blockLeftMins = 30 - Math.floor(minDiff);
        return true;
      } else {
        this.blocked = false;
        localStorage.removeItem('blocked');
        localStorage.removeItem('blocked_time');
        this.invalidAttempts = 0;
        return false;
      }
    }
    return false;
  }

  submitForm($ev: any, value: any, template: TemplateRef<void>) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.checkBlocked()) {
      this.blocked = true;
      return;
    }
    if (this.valForm.valid) {
      this.eventsService.broadcast('loader:show');
      this.Auth.login(value).subscribe({
        next: (res: any) => {
          this.eventsService.broadcast('loader:hide');
          if (res.status) {
            this.userId = res.id;
            this.activeView = 'token';
            this.show = !this.show;
            setTimeout(() => {
              // this will make the execution after the above boolean has changed
              this.searchElement.nativeElement.focus();
            }, 0);
          } else {
            this.userId = res.id;
            this.user_id = res?.user_id;
            const config = {
              class: 'modal-dialog-centered',
            };
            this.bsModalRef = this.modalService.show(template, config);
          }
        },
        error: (err: any) => {
          this.eventsService.broadcast('loader:hide');
          console.log(err);

          // Account is locked. Please try again later.
          if (err && err.error && err.retry_after) {
            this.invalidAttempts = 5;
            localStorage.setItem(
              'blocked_time',
              moment().format('YYYY-MM-DD HH:mm:ss')
            );
            localStorage.setItem('blocked', 'true');
            this.blocked = true;
            this.blockLeftMins = 30;
            this.toasterService.error(err.error);
          } else if (err && err.error) {
            this.invalidAttempts++;
            if (this.invalidAttempts > 5) {
              localStorage.setItem(
                'blocked_time',
                moment().format('YYYY-MM-DD HH:mm:ss')
              );
              localStorage.setItem('blocked', 'true');
              this.blocked = true;
              this.blockLeftMins = 30;
            }
            this.toasterService.error(err.error);
          } else {
            this.toasterService.error(
              'Something went wrong. We are looking into it.'
            );
          }
        },
      });
    }
  }

  submitTokenForm(ev: any, value: any) {
    this.eventsService.broadcast('loader:show');
    if (this.tokenForm.valid) {
      this.userService.verifyCode(this.userId, value).subscribe({
        next: (res: any) => {
          this.eventsService.broadcast('loader:hide');
          if (res.data && res.data.access_token) {
            this.settings.setUserSetting('name', res.data.first_name);
            var d = new Date();
            localStorage.setItem('lastLoginTime', d.getTime().toString());
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('account_type', 'admin_user');
            localStorage.setItem('scanId', res.data.last_published_scan_id);

            var unique_session_id = Math.floor(
              Math.random() * 1000000
            ).toString();
            var user_agent = navigator.userAgent;
            var osInfo = this.osService.getOperatingSystem();

            var active_session_data = {
              unique_session_id: unique_session_id,
              user_id: res.data.id,
              user_type: 'admin_user',
              user_agent: user_agent,
              os: osInfo.name,
              os_version: osInfo.version,
              ip_address: this.location?.IPv4 ?? '',
              location: this.location ?? '',
              jwt_token: res.data.access_token,
            };

            localStorage.setItem(
              'active_session_data',
              JSON.stringify(active_session_data)
            );

            this.activeSessionService
              .storeActiveSessionData(active_session_data, 'admin')
              .subscribe({
                next: (res: any) => {
                  this.sessionTimerService.startSessionTimer('admin');
                },
                error: (err: any) => {
                },
              });

            this.menuService.resetMenu();
            this.menuService.addMenu(admin_menu);
            this.router.navigate(['/dashboard']);
          } else {
            // this.tokenForm.get('token').setValue('');
            this.toasterService.error('Invalid token.');
          }
        },
        error: (err: any) => {
          this.eventsService.broadcast('loader:hide');
          if (err && err.error) {
            // this.tokenForm.get('token').setValue('');
            this.toasterService.error(err.error);
          } else {
            this.toasterService.error(
              'Something went wrong. We are looking into it.'
            );
          }
        },
      });
    }
  }

  ngOnInit() {
    if (localStorage.getItem('user') && localStorage.getItem('access_token')) {
      if (localStorage.getItem('account_type') === 'company_user') {
        this.route.navigate(['/company/dashboard']);
      } else {
        this.route.navigate(['/dashboard']);
      }
    } else {
      this.route.navigate(['/login']);
    }
  }

  forgotPassword() {
    this.route.navigate(['/forgot-password']);
  }

  continuingLogout() {
    this.activeSessionService
      .deleteActiveSessionByUser(this.user_id, 'admin', 'admin_user')
      .subscribe({
        next: (res: any) => {
          this.eventsService.broadcast('loader:hide');
          if (res.status) {
            this.bsModalRef.hide();
            this.activeView = 'token';
            this.show = !this.show;
            setTimeout(() => {
              // this will make the execution after the above boolean has changed
              this.searchElement.nativeElement.focus();
            }, 0);
          }
        },
        error: (err: any) => {
        },
      });
  }
}
