<div class="modal-header">
  <h4 class="modal-title pull-left m-0">Add New Department</h4>
  <button type="button" class="close pull-right p-0 btn" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="departmentForm" class="form-validate" (submit)="submitForm($event, departmentForm.value)" novalidate="">

    <fieldset>
      <div class="form-group row">
        <label class="col-sm-4 control-label fw-bold mb-2">Department Name*</label>
        <div class="col-sm-8">
          <input class="form-control" type="text" formControlName="name" placeholder="Enter Department Name" />
          <span class="text-danger"
            *ngIf="departmentForm.controls['name'].hasError('required') && (departmentForm.controls['name'].dirty || departmentForm.controls['name'].touched)">
            This field is required
          </span>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div class="form-group">
        <label class="control-label fw-bold mb-2">Locations*</label>
        <ng-select [items]="locations"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="Select Locations"
                   formControlName="locations"
                   [multiple]="true"
                   notFoundText="No locations found">
        </ng-select>
        <span class="text-danger"
          *ngIf="departmentForm.controls['locations'].hasError('required') &&
                 (departmentForm.controls['locations'].dirty || departmentForm.controls['locations'].touched)">
          This field is required
        </span>
      </div>
    </fieldset>

    <button class="pul-right btn btn-info" type="submit">Save</button>
    <a class="pull-right btn btn-default" (click)="bsModalRef.hide()">Close</a>
  </form>
</div>
