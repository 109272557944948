import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';

@Injectable()
export class EventsService {
  listeners: any;
  eventsSubject;
  events;
  constructor() {
    this.listeners = {};
    this.eventsSubject = new Rx.Subject();

    this.events = Rx.from(this.eventsSubject);

    this.events.subscribe(
      (name: any, ...args: any) => {
        if (this.listeners[name.name]) {
          for (let listener of this.listeners[name.name]) {
            listener(...name.args);
          }
        }
      });
  }

  on(name: any, listener: any) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  broadcast(name: any, ...args: any) {
    this.eventsSubject.next({
      name,
      args
    });
  }
}
