<div class="item user-block" *ngIf="userBlockIsVisible()">
  <!-- User picture-->
  <div class="user-block-picture">
    <div class="user-block-status">
      <img class="img-thumbnail img-circle" [src]="user?.picture" alt="Avatar" />
      <div class="circle circle-success circle-lg"></div>
    </div>
  </div>
  <!-- Name and Job-->
  <div class="user-block-info">
    <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }} {{user?.name | titlecase }}</span>
    <span class="user-block-role">{{user?.company?.name}} {{user?.role?.name | titlecase }}</span>
  </div>
</div>