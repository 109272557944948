<!-- <div class="modal-body">
  <h2 class="text-center"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h2>
  <p class="text-center">You have been idle since long time. You will be logged out in next 5 mins if you don't use the system.</p>
  <button class="btn btn-block btn-primary mt-lg" (click)="close()">
    Click here to prevent the logout
  </button>
</div> -->

<div class="modal-body">
  <h2 class="text-center"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h2>
  <p class="text-center">You have been idle since long time. You will be logged out in next 5 mins if you don't use the system.</p>
  <button class="btn btn-block btn-primary mt-lg w-100" (click)="close()">
    Click here to prevent the logout
  </button>
</div>
