import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { FullCalendarDirective } from './directives/full-calendar/full-calendar.directive';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { NoSanitizePipe } from './pipes/NoSanitize';
import { NgSelectModule } from '@ng-select/ng-select';
import { HighLightRowDirective } from './directives/highlight_row.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { FullCalendarModule } from '@fullcalendar/angular';
import { VulnListItemComponent } from './components/vuln-list-item/vuln-list-item.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LoaderComponent } from './components/loader/loader.component';
import { IdlePromptComponent } from './components/idle-prompt/idle-prompt.component';
import { LoggedOutModalComponent } from './components/logged-out-modal/logged-out-modal.component';
import { BoldPipe } from './pipes/bold-pipe/bold.pipe';
import { OrdinalPipe } from './pipes/ordinal-pipe/ordinal.pipe';
import { AutoScrollDirective } from './directives/auto-scroll/auto-scroll.directive';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
  declarations: [
    SearchBoxComponent,
    VulnListItemComponent,
    FullCalendarDirective,
    IdlePromptComponent,

    // Pipes
    SafeHtmlPipe,
    NoSanitizePipe,

    // Directives
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    HighLightRowDirective,
    AutoScrollDirective,

    // Components
    LoaderComponent,
    IdlePromptComponent,
    LoggedOutModalComponent,
    BoldPipe,
    OrdinalPipe,


  ],
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgApexchartsModule,
    NgSelectModule,
    FullCalendarModule,
    EditorModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    HighLightRowDirective,
    AutoScrollDirective,

    // // Components
    SearchBoxComponent,
    VulnListItemComponent,
    NgApexchartsModule,
    NgSelectModule,
    FullCalendarModule,
    IdlePromptComponent,



    // Pipes
    SafeHtmlPipe,
    NoSanitizePipe,
    EditorModule,
    LoaderComponent,
    BoldPipe,
    OrdinalPipe
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
    };
  }
}
