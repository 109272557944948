import { Directive, ElementRef } from '@angular/core';
declare var $: any;

@Directive({
	selector: '[highlightRow]'
})
export class HighLightRowDirective {

	constructor(public el: ElementRef) {
		let $element = $(el.nativeElement);
		$($element).click(function() {
			let selected = $($element).hasClass('highlight');
			$('tr').removeClass('highlight');
			if (!selected) {
				$($element).addClass('highlight');
			}
		});
	}

}
