// import { Component, OnInit } from '@angular/core';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// @Component({
//   selector: 'app-idle-prompt',
//   templateUrl: './idle-prompt.component.html',
//   styleUrls: ['./idle-prompt.component.scss']
// })
// export class IdlePromptComponent implements OnInit {

//   constructor(
//     public bsModalRef: BsModalRef,
//     public modalService: BsModalService,
//   ) { }

//   ngOnInit() {
//   }

//   close() {
//     this.bsModalRef.hide();
//   }

// }
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-idle-prompt',
  templateUrl: './idle-prompt.component.html',
  styleUrls: ['./idle-prompt.component.scss']
})
export class IdlePromptComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
  ) { }

  ngOnInit() {
  }

  close() {
    this.bsModalRef.hide();
  }

}