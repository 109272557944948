import { EventsService } from './../../../core/services/events.service';
import { Directive, AfterViewInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

interface JQuery {
  fullCalendar(options?: any, options2?: any): any;
}

@Directive({
  selector: '[fullCalendar]',
})
export class FullCalendarDirective implements AfterViewInit {

  @Input('config') config: object = {};
  @Output() action = new EventEmitter();
  defaultConfig: object = {
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,basicWeek,basicDay'
    },
    editable: true,
    eventLimit: true, // allow "more" link when too many events
    navLinks: true,
  };
  constructor(public el: ElementRef,
    public event: EventsService) {
      Object.assign(this.defaultConfig, this.config);
      ($(this.el.nativeElement) as any).fullCalendar(this.defaultConfig);

      this.event.on('event:add', (data: any) => {
        ($(this.el.nativeElement) as any).fullCalendar('renderEvent', data);
      });

      this.event.on('event:delete', (data: any) => {
        ($(this.el.nativeElement) as any).fullCalendar('removeEvents', data.id);
      });

      this.event.on('event:update', (data: any) => {
        ($(this.el.nativeElement) as any).fullCalendar('removeEvents', data.id);
        ($(this.el.nativeElement) as any).fullCalendar('renderEvent', data);
      });

    }

  ngAfterViewInit() {

  }

}
