import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveSessionService {
  constructor(public api: ApiService) {}

  storeActiveSessionData(body: Object, route_prefix: any) {
    return this.api.post('/' + route_prefix + '/store-active-session', body);
  }

  updateActiveSessionData(body: Object, route_prefix: any) {
    return this.api.post('/' + route_prefix + '/update-active-session', body);
  }

  deleteActiveSessionData(body: Object, route_prefix: any) {
    return this.api.post('/' + route_prefix + '/delete-active-session', body);
  }

  deleteActiveSessionByUser(user_id: any, route_prefix: any, user_type: any) {
    return this.api.get(
      '/' +
        route_prefix +
        '/delete-active-session-user/' +
        user_type +
        '/' +
        user_id
    );
  }

  getActiveSessionList(user_id: any, route_prefix: any, user_type: any) {
    return this.api.get(
      '/' + route_prefix + '/active-session-list/' + user_type + '/' + user_id
    );
  }
}
