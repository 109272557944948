<div class="wrapper company-wrapper">
  <div class="mx-auto mt-xl wd-xl">
    <!-- START card-->
    <div class="card card-dark card-flat">
      <div class="card-header text-center">
        <h1 class="m-0">Scanview v2.0</h1>
        <p>Client Portal</p>
      </div>
      <div class="card-body" *ngIf="activeView === 'login'">
        <p class="text-center py-3 mb-3">SIGN IN TO CONTINUE.</p>
        <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="loginForm" novalidate=""
          (ngSubmit)="submitForm($event, valForm.value, template)">
          <div class="form-group position-relative mb-3 has-feedback">
            <input class="form-control pe-5 has-feedback" id="exampleInputEmail1" type="email" name="email"
              autocomplete="off" formControlName="email" placeholder="Email" required="" autofocus />
            <span class="fa fa-envelope form-control-feedback position-absolute text-muted"></span>
            <!-- <span class="text-danger" *ngIf="email.errors?required && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field is required</span> -->
            <div *ngIf="email?.touched && email?.invalid">
              <span class="text-danger" *ngIf="email?.errors?.['required']">This field is required.</span>
            </div>
            <span class="text-danger" *ngIf="valForm.controls['email'].hasError('pattern')">Please enter a valid
              email</span>

          </div>
          <div class="form-group has-feedback position-relative mb-3">
            <input class="form-control pe-5 has-feedback" id="exampleInputPassword1" type="password" name="password"
              formControlName="password" placeholder="Password" required="" />
            <span class="fa fa-lock form-control-feedback position-absolute text-muted"></span>
            <!-- <span class="text-danger" *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">This field is required</span> -->

            <div *ngIf="password?.touched && password?.invalid">
              <span class="text-danger" *ngIf="password?.errors?.['required']">This field is required.</span>
            </div>
            <span class="text-danger" *ngIf="valForm.controls['password'].hasError('blankSpace')">This field cannot
              consist of only blank spaces</span>
            <span class="text-danger"
              *ngIf="valForm.controls['password'].hasError('minlength') && !valForm.controls['password'].hasError('blankSpace')">Password
              must be at least 6 characters</span>
            <span class="text-danger"
              *ngIf="valForm.controls['password'].hasError('maxlength') && !valForm.controls['password'].hasError('blankSpace')">Password
              cannot exceed 20 characters</span>
          </div>
          <div class="clearfix">
            <!-- <div class="checkbox c-checkbox pull-left mt0">
                          <label>
                              <input type="checkbox" value="" name="account_remember"/>
                              <span class="fa fa-check"></span>Remember Me</label>
                      </div> -->
            <!-- <div class="pull-right"><a class="text-muted" [routerLink]="'/recover'">Forgot your password?</a>
                      </div> -->
          </div>
          <div class="py-3">
            <button class="btn btn-block btn-primary mt-3" type="submit">Login</button>
          </div>
          <br>
          <a (click)="forgotPassword()"> Forgot password?</a>
        </form>
      </div>
      <div class="card-body" [hidden]="activeView === 'login'">
        <h3 class="text-center pv">
          <i class="fa fa-lock" aria-hidden="true"></i> Enter Authentication Token
        </h3>
        <form [formGroup]="tokenForm" class="form-validate mb-lg" role="form" name="qrCodeForm" novalidate=""
          (submit)="submitTokenForm($event, tokenForm.value)">
          <div class="form-group has-feedback position-relative mb-3">
            <input autofocus class="form-control pe-4 has-feedback" id="token" type="password" name="token"
              placeholder="Token" formControlName="token" required="" maxlength="6" />
            <span class="fa fa-lock form-control-feedback position-absolute text-muted"></span>
            <span class="text-danger"
              *ngIf="tokenForm.controls['token'].hasError('required') && (tokenForm.controls['token'].dirty || tokenForm.controls['token'].touched)">This
              field is required</span>
            <span class="text-danger" *ngIf="tokenForm.controls['token'].hasError('minlength')">Token must be 6
              characters long.</span>
          </div>
          <div class="clearfix">
          </div>
          <button class="btn btn-block btn-info mt-lg" type="submit">Verify</button>
        </form>
      </div>
    </div>
    <!-- END card-->
    <div class="p-lg text-center copy-right">
      <span>&copy;</span>
      <span>{{ settings.app.year }}</span>
      <span>-</span>
      <span>{{ settings.app.name }}</span>
      <br />
      <span>{{ settings.app.description }}</span>
    </div>
  </div>
  <!-- Modal -->
  <ng-template #template>
    <div class="modal-header p-3">
      <h4 class="modal-title pull-left">Logout From Other Session</h4>
      <button type="button" class="close pull-right p-0 btn" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="">
        <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path
              d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
          </svg> You are already logged in from another device, continuing will log out you.</p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark border border-dark cancel_btn" (click)="bsModalRef.hide()"><svg
          xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg> <span class="ps-2 pt-1">No, Cancel</span></button>
      <button type="button" class="btn btn-primary" (click)="continuingLogout()"><svg xmlns="http://www.w3.org/2000/svg"
          height="16" width="14" viewBox="0 0 448 512">
          <path fill="#ffffff"
            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
        </svg> <span class="ps-2">Logout and continue</span></button>
    </div>
  </ng-template>
</div>
