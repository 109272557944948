<div class="wrapper company-wrapper">
  <div class="mx-auto mt-xl wd-xl">
    <!-- START card-->
    <div class="card card-dark card-flat">
      <div class="card-header text-center">
        <h1>Scanview v2.0</h1>
        <!-- <img class="block-center img-rounded" src="assets/img/preloader/preloader.full.png" alt="Image" /> -->
      </div>
      <div class="card-body">
        <p class="text-center pv pb-3 my-3">FORGOT YOUR PASSWORD</p>
        <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="loginForm" novalidate="" (submit)="submitForm($event, valForm.value)">
          <div class="form-group position-relative has-feedback">
            <input class="form-control" id="exampleInputEmail1" type="email" name="email" placeholder="Enter email" autocomplete="off"
              formControlName="email" required="" />
              <span class="fa fa-envelope form-control-feedback position-absolute text-muted"></span>
            <span class="text-danger" *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field is required</span>
            <span class="text-danger" *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field must be a valid email address</span>
          </div>

          <div class="form-group recaptcha-container">
            <re-captcha formControlName="captcha" [siteKey]="siteKey"
              (resolved)="onRecaptchaResolved($event)"></re-captcha>
            <span class="text-danger"
              *ngIf="valForm.controls['captcha'].hasError('required') && valForm.controls['captcha'].touched">
              Please complete the reCAPTCHA
            </span>
          </div>

          <button class="btn btn-block btn-primary mt-md" type="submit">Send</button>
          <button class="btn btn-block btn-danger mt-lg" (click)="cancel()">Cancel</button>
        </form>
      </div>
    </div>
    <!-- END card-->
    <!-- <div class="p-lg text-center">
      <span>&copy;</span>
      <span>{{ settings.app.year }}</span>
      <span>-</span>
      <span>{{ settings.app.name }}</span>
      <br/>
      <span>{{ settings.app.description }}</span>
    </div> -->
  </div>
</div>
