import { SettingsService } from './../../../core/settings/settings.service';
import { AuthService } from './../../../core/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from './../../../core/services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component } from '@angular/core';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {


  valForm: FormGroup;
  sentAttempts = 0;

  constructor(
    public route: Router,
    public eventsService: EventsService,
    private toastr: ToastrService,
    private auth: AuthService,
    public settings: SettingsService
  ) {

    this.valForm = new FormGroup({
      email: new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+([.]*[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]))
    });

  }

  ngOnInit() {
  }

  cancel() {
    this.route.navigate(['/login']);
  }

  submitForm(ev: any, value: any) {
    ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (!sessionStorage.getItem('sentAttempts')) {
      sessionStorage.setItem('sentAttempts', '1');
    }
    var attempts: any
    attempts = sessionStorage.getItem('sentAttempts');
    let atmpts: any = parseInt(attempts, 10);
    if (atmpts && atmpts > 3) {
      this.toastr.success('Looks like something fishy is going on here! Please try again later.');
      return;
    }
    if (this.valForm.valid) {
      this.eventsService.broadcast('loader:show');
      this.auth.adminForgotPassword(value).subscribe({
        next: (res: any) => {
          this.eventsService.broadcast('loader:hide');
          this.toastr.success('Link Sent.','Forgot Password Link Sent Successfully.');
          atmpts++;
          sessionStorage.setItem('sentAttempts', atmpts);
          this.route.navigate(['/login']);
        },
        error: (err: any) => {
          this.eventsService.broadcast('loader:hide');
          if (err && err.error) {
            this.toastr.error(err.error);
          } else {
            this.toastr.error('Something went wrong. We are looking into it.');
          }
        }
      })
    }
  }

}
