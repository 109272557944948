import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { EventsService } from 'src/app/core/services/events.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class CompanyForgotPasswordComponent implements OnInit {

  valForm: FormGroup;

  siteKey = environment.Sitekey;
  recaptchaResponse: string = '';

  constructor(
    public route: Router,
    public eventsService: EventsService,
    public toasterService: ToastrService,
    public fb: FormBuilder,
    private auth: AuthService) {

    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      captcha: new FormControl(null, Validators.required)
    });

  }

  ngOnInit() {
  }

  cancel() {
    this.route.navigate(['/company_auth/login']);
  }

  onRecaptchaResolved(response: any) {
    this.recaptchaResponse = response;
  }

  submitForm($ev: any, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.eventsService.broadcast('loader:show');
      this.auth.companyForgotPassword(value).subscribe({
        next: res => {
          this.eventsService.broadcast('loader:hide');
          this.toasterService.success('Forgot Password Link Sent Successfully.', 'Link Sent.');
          this.route.navigate(['/company/login']);
        },
        error: err => {
          this.eventsService.broadcast('loader:hide');
          if (err) {
            if (err && err.error) {
              this.toasterService.error(err.error, 'Error');

              if (err.error == 'ReCAPTCHA validation failed') {
                this.valForm?.get('captcha')?.setValue('');
              }
            } else {
              this.toasterService.error('Something went wrong. We are looking into it.', 'Error');
            }
          }
        }
      });
    }
  }

}
