import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-vuln-list-item',
  templateUrl: './vuln-list-item.component.html',
  styleUrls: ['./vuln-list-item.component.scss']
})
export class VulnListItemComponent implements OnInit {

  @Input() item: any;
  @Input() isActive = false;
  @Output() onClick = new EventEmitter();

  ngOnInit() {
  }

  itemClicked() {
    this.onClick.emit(this.item);
  }
}
