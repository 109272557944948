<div class="wrapper">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper topnavbar" [sideBar]="sideBar"></app-header>
  <!-- sidebar-->
  <app-sidebar class="aside position-fixed"></app-sidebar>
  <!-- offsidebar-->
  <app-offsidebar class="offsidebar" #sideBar></app-offsidebar>
  <!-- Main section-->
  <section>
      <!-- Page content-->
      <div class="content-wrapper">
          <router-outlet></router-outlet>
      </div>
  </section>
  <!-- Page footer-->
  <footer app-footer></footer>
</div>
