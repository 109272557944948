import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-logged-out-modal',
  templateUrl: './logged-out-modal.component.html',
  styleUrls: ['./logged-out-modal.component.scss']
})
export class LoggedOutModalComponent implements OnInit{
  constructor(public toasterService: ToastrService, public bsModalRef: BsModalRef){}

  ngOnInit(): void {

    setTimeout(() => {
      this.bsModalRef.hide(); // Hide the modal after 10 Sec
    }, 10000);

    this.toasterService.error(
      "You've been logged out due to an active session on another device."
    );
  }
}
