<div id="event_{{item.id}}"
     *ngIf="item?.id"
     class="clearfix bb br text-sm cp single-item"
     [ngClass]="{'active-item': item?.isActive}"
     (click)="itemClicked()">

    <div class="media-box">
        <div class="media-box-body custom-pad clearfix">

            <div class="clearfix">
                <div class="fw-bold">
                    #{{ item.plugin_id }} - {{ item.name }}
                </div>
                <div class="clearfix" [ngSwitch]="item.risk">

                    <span class="valign-middle">
                        <input *ngIf="!item.flag" type="checkbox" (click)="$event.stopPropagation()" [(ngModel)]="item.checked">
                        <span class="image-icon" *ngIf="item.is_image"><i class="fa fa-image" aria-hidden="true"></i></span>
                    </span>

                    <span class="valign-middle vuln badge bg-danger-dark" *ngSwitchCase="'Critical'">
                        {{item.risk}}
                    </span>
                    <span class="valign-middle vuln badge bg-primary" *ngSwitchCase="'Info'">
                        {{item.risk}}
                    </span>
                    <span class="valign-middle vuln badge bg-danger" *ngSwitchCase="'High'">
                        {{item.risk}}
                    </span>
                    <span class="valign-middle vuln badge bg-warning" *ngSwitchCase="'Medium'">
                        {{item.risk}}
                    </span>
                    <span class="valign-middle vuln badge bg-success" *ngSwitchCase="'Low'">
                        {{item.risk}}
                    </span>
                </div>
            </div>

            <div class="clearfix">
                <p *ngIf="item.host_count > 0">
                    Hosts Affected: <span class="badge bg-gray">{{item.host_count}}</span>
                    Ports Affected: <span class="badge bg-gray">{{item.port_count}}</span>
                    Total Affected: <span class="badge bg-gray">{{item.total_count}}</span>
                    <span class="badge bg-danger" *ngIf="item.comment_count > 0"><i class="fa fa-commenting-o" aria-hidden="true"></i>  {{item.comment_count}}</span>
                </p>
                <p>{{ item.synopsis }}</p>
            </div>
        </div>
    </div>


</div>
