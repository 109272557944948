import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {

  styleTag: any;
  defaultTheme = 'G';

  constructor() { 
    this.createStyle();
    let test = localStorage.getItem('theme');
    if(test != null){      
      this.injectStylesheet(test);
    }
    else{      
      this.injectStylesheet(this.defaultTheme);
    }    
  }

  private createStyle() {  
    const head = document.head || document.getElementsByTagName('head')[0];
    this.styleTag = document.createElement('link');
    this.styleTag.type = 'text/css';
    this.styleTag.id = 'appthemes';
    this.styleTag.rel = 'stylesheet';
    this.styleTag.href = `theme${this.defaultTheme}.css`;
    head.appendChild(this.styleTag);   
  }


  injectStylesheet(css: any) {    
    this.styleTag.href = `theme${css}.css`;
  }

  getDefaultTheme() {
    
    let test = localStorage.getItem('theme');
    if(test == this.defaultTheme){      
      return this.defaultTheme;
    }
    else{      
      return this.defaultTheme;
    }
    
  }

}
