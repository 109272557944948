import {animate, state, style, transition, trigger} from '@angular/animations';

export let fade = trigger('fade', [
	state('void', style({opacity: 0})),
	transition(':enter, :leave', [
		animate('0.3s ease-in')
	])
]);

export let fadeIn = trigger('fadeIn', [
	state('void', style({opacity: 0})),
	transition(':enter', [
		animate('0.3s 0.2s ease-in')
	])
]);

export let fadeOut = trigger('fadeOut', [
	state('void', style({opacity: 0})),
	transition(':leave', [
		animate('0.3s ease-in')
	])
]);

export let rotateBackArrow = trigger('rotateBackArrow', [
	state('void', style({transform: 'rotate(-90deg)' })),
	transition(':enter', [
		animate('0.3s ease-in')
	]),
	transition(':leave', [
		animate('0.3s ease-in')
	])
]);

