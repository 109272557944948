<!-- START Top Navbar-->
<nav class="navbar navbar-expand-lg topnavbar" role="navigation">
  <div class="container-fluid">
    <a class="navbar-brand" href="#/">
      <div class="brand-logo">
        <img class="img-responsive" src="assets/img/preloader/preloader.full.png" alt="App Logo" height="30" />
      </div>
    </a>
    <button class="navbar-toggler collapsed" type="button" (click)="navCollapsed = !navCollapsed">
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-menu"></span>
    </button>
  </div>
  <!-- START Nav wrapper-->
  <div class="collapse navbar-collapse" [collapse]="navCollapsed">
    <!-- START Right Navbar-->
    <div class="navbar-nav navbar-right d-flex justify-content-between">

      <li class="dropdown dropdown-list" [class.open]="dropdownOpen" id="notifications-menu">
        <a class="header-right-icons relative" (click)="toggleDropdown($event)">
          <i class="fa fa-bell-o" aria-hidden="true"></i>
          <span class="position-absolute top-0  badge rounded-pill bg-danger end-0">{{ notificationCount }}</span>
        </a>
        <!-- START Dropdown menu-->
        <ul *ngIf="dropdownOpen" class="dropdown-menu overflow-auto" role="menu" aria-labelledby="notifications-menu">
          <li *ngIf="notificationCount === 0" role="menuitem">
            <div class="px-3 py-2">
              <p class="text-center m-0">No new notifications</p>
            </div>
          </li>
          <li *ngFor="let notification of notificationData;" role="menuitem" class="cursor-pointer"
            (click)="handleNotificationClick(notification.id)">
            <div class="px-3 py-2">
              <div class="d-flex align-items-center">
                <div class="me-2">
                  <i class="fa fa-info-circle text-primary"></i>
                </div>
                <div>
                  <p class="pb-1 m-0 d-flex justify-content-between">
                    <strong>{{ (notification.subject.length > 30) ? (notification.subject | slice: 0:30) + '...' :
                      (notification.subject) }}</strong>
                    <span>{{ notification.sent_at | date:'MMMM d h:mm a' }}</span>
                  </p>
                  <p class="notification-description mb-0">{{ (notification.message.length > 110) ?
                    (notification.message
                    |
                    slice: 0:110) + '...' : (notification.message) }}</p>
                </div>
              </div>
            </div>
            <div class="divider dropdown-divider"></div>
          </li>
          <li *ngIf="notificationCount > 0" role="menuitem">
            <div class="d-flex w-full px-3 justify-between">
              <a class="" href="#" (click)="markAllAsRead($event, false)">Mark All Read</a>
              <a class="text-right" href="#" (click)="markAllAsRead($event, true)">View All</a>
            </div>
          </li>
          <li *ngIf="notificationCount == 0" role="menuitem">
            <div class="d-flex w-full px-3 justify-between">
              <a class="text-right" href="#" (click)="goToNotificationsPage($event)">View Past Notifications</a>
            </div>
          </li>
        </ul>
      </li>
      <!-- END Alert menu-->

      <!-- START Offsidebar button-->
      <li>
        <a class="header-right-icons" (click)="toggleOffsidebar()">
          <em class="icon-notebook"></em>
        </a>
      </li>
      <!-- Logout -->
      <li>
        <a class="header-right-icons" (click)="logout()">
          <em class="icon-logout"></em>
        </a>
      </li>
      <!-- END Offsidebar menu-->
    </div>
    <!-- END Right Navbar-->
  </div>
  <!-- END Nav wrapper-->
  <app-navsearch [hidden]="true" (onclose)="setNavSearchVisible(false)"></app-navsearch>
</nav>
