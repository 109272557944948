import { EventsService } from './../../../../../core/services/events.service';
import { CompanyService } from './../../../../../core/services/company.service';
import { AuthService } from './../../../../../core/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.scss']
})
export class EditDepartmentComponent implements OnInit {

  departmentForm!: FormGroup;
  companyId!: number;
  department: any;
  allLocations: any;
  availableLocations: any = [];
  bodyReady = false;
  selectedLocations: any;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private companyService: CompanyService,
    private auth: AuthService,
    private toasterService: ToastrService
  ) {}

  ngOnInit() {
    // Initialize the form with values
    this.departmentForm = this.formBuilder.group({
      name: [this.department?.name || '', [Validators.required, this.noWhitespaceValidator()]],
      company_id: [this.companyId, Validators.required] // Added company_id field to the form
    });

    // Populate available locations and mark body ready
    this.populateLocations();
    this.bodyReady = true;
  }

  populateLocations() {
    const existingLocations = this.department?.locations.map((loc: any) => loc.id) || [];

    this.availableLocations = this.allLocations.filter((loc: any) =>
      !existingLocations.includes(loc.id));
  }

  detachDepartmentLocation(location_id: any, idx: any) {
    if (!location_id || !this.department.id) {
      console.error("Invalid location_id or department_id.");
      this.toasterService.error("Invalid data provided. Cannot detach location.", "Error");
      return;
    }

    console.log("Detaching location with ID:", location_id, "from department with ID:", this.department.id);

    if (confirm('Are you sure you want to detach location from department?')) {
      const requestBody = {
        department_id: this.department.id,
        location_id: location_id,
        name: this.departmentForm.controls['name'].value,
        company_id: this.companyId
      };

      this.companyService.removeLocationFromDepartMent(requestBody).subscribe({
        next: (res: any) => {
          this.eventsService.broadcast('companyLocation:refresh');
          this.eventsService.broadcast('departments:refresh');
          this.department.locations.splice(idx, 1);
          this.auth.accessLog({
            data_id: location_id,
            module_id: this.auth.module_ids.company,
            action: 'company_detach_department_location',
            description: this.department.id,
            requested_url: '',
            method_type: 'put'
          }).subscribe();
        },
        error: (err: any) => {
          this.toasterService.error('Failed to detach location.', 'Error');
          console.error("Error detaching location:", err);
        }
      });
    }
  }

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }


  submitForm(e: any, v: any) {
    e.preventDefault();
    for (let c in this.departmentForm.controls) {
      this.departmentForm.controls[c].markAsTouched();
    }

    if (!this.departmentForm.valid) {
      return false;
    }
    v.company_id = this.companyId;
    v.email = this.department.email;
    this.eventsService.broadcast('loader:show');
    this.companyService.updateDepartment(v, this.department.id).subscribe({
      next: (res: any) => {
        this.eventsService.broadcast('loader:hide');
        this.eventsService.broadcast('companyLocation:refresh');
        this.eventsService.broadcast('departments:refresh');
        this.auth.accessLog({
          data_id: this.department.id,
          module_id: this.auth.module_ids.company,
          action: 'company_department_update',
          requested_url: '',
          method_type: 'put'
        }).subscribe();
        this.toasterService.success('Department updated successfully.', 'Success');
        this.bsModalRef.hide();
      },
      error: (err: any) => {
        this.eventsService.broadcast('loader:hide');
        this.toasterService.error(err?.error?.message || 'Something went wrong. We are looking into it.', 'Error');
      }
    });
    return true;
  }

  addLocations() {
    const body = {
      department_id: this.department.id,
      locations: this.selectedLocations
    };
    this.eventsService.broadcast('loader:show');
    this.companyService.addLocationsToDepartments(body).subscribe({
      next: (res: any) => {
        this.eventsService.broadcast('loader:hide');
        this.eventsService.broadcast('companyLocation:refresh');
        this.eventsService.broadcast('departments:refresh');
        this.auth.accessLog({
          module_id: this.auth.module_ids.company,
          action: 'company_add_department_locations',
          requested_url: '',
          method_type: 'post'
        }).subscribe();
        this.toasterService.success('Location updated successfully.', 'Success');
        this.bsModalRef.hide();
      },
      error: (err: any) => {
        this.eventsService.broadcast('loader:hide');
        if (err && err.error && err.error.message) {
          if (err.error === 'token_expired') {
            this.bsModalRef.hide();
          }
          this.toasterService.error(err.message, 'Error');
        } else {
          this.toasterService.error('Something went wrong. We are looking into it.', 'Error');
        }
      }
    });
  }
}
