import { Injectable } from '@angular/core';

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { EventsService } from '../events.service';
import { catchError, map } from 'rxjs/operators';
import { ActiveSessionService } from '../active-session.service';
import { SessionTimerService } from '../session-timer.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private eventService: EventsService,
    public activeSessionService: ActiveSessionService,
    public sessionTimerService: SessionTimerService,
    public bsModalRef: BsModalRef,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('access_token');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg: any;

        if (error instanceof HttpErrorResponse) {
          errorMsg = error.error;
          this.eventService.broadcast('loader:hide');
          if (error.status === 401) {
            this.bsModalRef.hide();
            var account_type = localStorage.getItem('account_type');
            let active_session_data = localStorage.getItem('active_session_data');
            if (active_session_data) {
              var session_obj = JSON.parse(active_session_data);
              var prefix_name = (account_type && account_type === 'admin_user') ? 'admin' : 'company';
              this.activeSessionService
                .deleteActiveSessionData(session_obj, prefix_name)
                .subscribe({
                  next: (res: any) => {
                    this.sessionTimerService.stopSessionTimer();
                  },
                  error: (err: any) => {
                  },
                });
            }
            localStorage.clear();
            if (account_type && account_type === 'company_user') {
              this.router.navigate(['/company/login']);
            } else {
              this.router.navigate(['/login']);
            }
          }
        }
        return throwError(() => errorMsg);
      })
    );
  }
}
