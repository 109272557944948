import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menuItems: any;

  constructor() {
    this.menuItems = [];
  }

  resetMenu() {
    this.menuItems = [];
  }

  addMenu(items: Array<{
    text: string,
    permission?: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    submenu?: Array<any>
  }>) {
    let menu: any = [];
    let user: any = JSON.parse(localStorage.getItem('user') || '{}');

    if (user && user.permissions) {
      items.forEach((item: any) => {
        if (item.permission) {
          if (user.permissions?.indexOf(item.permission) !== -1) {
            let tmp = item;
            if (item.submenu) {
              for (let i = tmp.submenu.length - 1; i >= 0; i--) {
                if (user.permissions?.indexOf(tmp.submenu[i].permission) === -1) {
                  tmp.submenu.splice(i, 1);
                }
              }
            }
            menu.push(tmp);
          }
        } else {
          menu.push(item);
        }

      });

      this.menuItems = menu;
    }
  }

  getMenu() {
    return this.menuItems;
  }

}
