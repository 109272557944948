import { Injectable } from '@angular/core';
import { timer, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActiveSessionService } from './active-session.service';
import { LoggedOutModalComponent } from 'src/app/shared/components/logged-out-modal/logged-out-modal.component';

@Injectable({
  providedIn: 'root',
})
export class SessionTimerService {
  private timerSubscription: Subscription;

  constructor(
    private activeSessionService: ActiveSessionService,
    private modalService: BsModalService,
  ) {}

  startSessionTimer(prefix: any): void {
    // Call Update Active Session API every 2 minutes
    this.timerSubscription = timer(0, 120000)
      .pipe(
        switchMap(() => {
          let local_session_data = JSON.parse(
            localStorage.getItem('active_session_data') || '{}'
          );
          return this.activeSessionService.updateActiveSessionData(
            {
              unique_session_id: local_session_data.unique_session_id,
            },
            prefix
          ) as Observable<any>;
        })
      )
      .subscribe({
        next: (res: any) => {
        },
        error: (err: any) => {
          if (err.error == 'session_logout') {
            // Logout the previous User if they logged in in another device Using logout and continue
            this.handleSessionLogout();
          }
        },
      });
  }

  private handleSessionLogout(): void {
    // Display logged out warning message in modal
    const config = {
      class: 'modal-dialog-centered',
    };
    this.modalService.show(LoggedOutModalComponent, config);
  }

  stopSessionTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
