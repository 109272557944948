import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuService } from 'src/app/core/menu/menu.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CompanyUserService } from 'src/app/core/services/company-user.service';
import { EventsService } from 'src/app/core/services/events.service';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { company_menu } from '../../company-menu';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActiveSessionService } from 'src/app/core/services/active-session.service';
import { OsService } from 'src/app/core/services/os.service';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap, timer } from 'rxjs';
import { SessionTimerService } from 'src/app/core/services/session-timer.service';
import { customEmailValidator, noBlankSpaceValidator } from 'src/app/core/services/common-validators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.scss']
})
export class CompanyLoginComponent implements OnInit {

  valForm!: FormGroup;
  tokenForm: FormGroup;
  activeView = 'login';
  userId: Number;
  user_id = Number;
  bsModalRef: BsModalRef;
  location: any;

  get email() {
    return this.valForm.get('email');
  }

  get password() {
    return this.valForm.get('password');
  }

  siteKey = environment.Sitekey;
  recaptchaResponse: string = '';

  constructor(
    public settings: SettingsService,
    public fb: FormBuilder,
    public Auth: AuthService,
    public route: Router,
    public companyUserService: CompanyUserService,
    public router: Router,
    public eventsService: EventsService,
    public toasterService: ToastrService,
    public menuService: MenuService,
    private modalService: BsModalService,
    public activeSessionService: ActiveSessionService,
    public osService: OsService,
    public sessionTimerService: SessionTimerService,
    http: HttpClient,
  ) {

    this.valForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+([.]*[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')])],
      password: [null, Validators.compose([Validators.required, noBlankSpaceValidator, Validators.minLength(6), Validators.maxLength(20)])],
      captcha: new FormControl(null, Validators.required)
    })

    // this.valForm = fb.group({
    // 	'email':
    // 	'password':
    // });

    this.tokenForm = new FormGroup({
      token: new FormControl('', [
        Validators.minLength(6),
        Validators.maxLength(8),
      ]),
    });
    http.get('https://geolocation-db.com/json/').subscribe((locationRes) => {
      this.location = locationRes;
    });
  }

  onRecaptchaResolved(response: string) {
    this.recaptchaResponse = response;
  }

  submitForm($ev: any, value: any, template: TemplateRef<void>) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.eventsService.broadcast('loader:show');
      this.Auth.companyLogin(value).subscribe({
        next: (res: any) => {

          this.eventsService.broadcast('loader:hide');
          if (res.status) {
            this.userId = res.id;
            this.activeView = 'token';

            localStorage.setItem('scanId', res.last_published_scan_id);
            localStorage.setItem('scanDate', res.last_published_scan_date);
          } else {
            this.userId = res.id;
            this.user_id = res?.user_id;
            const config = {
              class: 'modal-dialog-centered'
            };
            this.bsModalRef = this.modalService.show(template, config);
          }
        },
        error: (err: any) => {
          this.eventsService.broadcast('loader:hide');
          if (err) {
            if (err && err.error) {
              this.toasterService.error(err.error, 'Error');

              if (err.error == 'ReCAPTCHA validation failed') {
                this.valForm?.get('captcha')?.setValue('');
              }
            } else if (err && err.message) {
              this.toasterService.error(err.message, 'Error');
            } else {
              this.toasterService.error('Something went wrong. We are looking into it.', 'Error');
            }
          }
        }
      });
    }
  }

  submitTokenForm($ev: any, value: any) {
    this.eventsService.broadcast('loader:show');
    for (let c in this.tokenForm.controls) {
      this.tokenForm.controls[c].markAsTouched();
    }
    if (this.tokenForm.valid) {
      this.companyUserService.verifyCode(this.userId, value).subscribe({
        next: (res: any) => {
          this.eventsService.broadcast('loader:hide');
          if (res.data && res.data.access_token) {
            this.settings.setUserSetting('name', res.data.first_name);
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('account_type', 'company_user');

            var unique_session_id = Math.floor(
              Math.random() * 1000000
            ).toString();
            var user_agent = navigator.userAgent;
            var osInfo = this.osService.getOperatingSystem();

            var active_session_data = {
              unique_session_id: unique_session_id,
              user_id: res.data.id,
              user_type: 'company_user',
              user_agent: user_agent,
              os: osInfo.name,
              os_version: osInfo.version,
              ip_address: this.location?.IPv4 ?? '',
              location: this.location ?? '',
              jwt_token: res.data.access_token,
            };

            localStorage.setItem(
              'active_session_data',
              JSON.stringify(active_session_data)
            );

            this.activeSessionService
              .storeActiveSessionData(active_session_data, 'company')
              .subscribe({
                next: (res: any) => {
                  this.sessionTimerService.startSessionTimer('company');
                },
                error: (err: any) => {
                },
              });

            this.menuService.resetMenu();
            this.menuService.addMenu(company_menu);
            this.router.navigate(['/company/dashboard']);
          } else {
            this.tokenForm.get('token')?.setValue('');
            this.toasterService.error('Invalid token.', 'Error');
          }
        },
        error: err => {
          this.eventsService.broadcast('loader:hide');
          if (err) {
            if (err && err.error) {
              this.tokenForm.get('token')?.setValue('');
              this.toasterService.error(err.error, 'Error');
            } else if (err && err.message) {
              this.tokenForm.get('token')?.setValue('');
              this.toasterService.error(err.message, 'Error');
            } else {
              this.toasterService.error('Something went wrong. We are looking into it.', 'Error');
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    if (localStorage.getItem('user')) {
      this.route.navigate(['/company/dashboard']);
    }
  }

  forgotPassword() {
    this.route.navigate(['/company_auth/company-forgot-password']);
  }

  continuingLogout() {
    this.activeSessionService
      .deleteActiveSessionByUser(this.user_id, 'company', 'company_user')
      .subscribe({
        next: (res: any) => {
          this.eventsService.broadcast('loader:hide');
          if (res.status) {
            this.bsModalRef.hide();
            this.activeView = 'token';
          }
        },
        error: (err: any) => {
        },
      });
  }

}
