import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public api: ApiService) { }

  create(body: Object) {
    return this.api.post('/admin/user', body);
  }

  get() {
    return this.api.get('/admin/user');
  }

  getById(id: Number) {
    return this.api.get('/admin/user/' + id);
  }

  getRoles() {
    return this.api.get('/admin/role');
  }

  update(body: Object, id: any) {
    return this.api.put('/admin/user/' + id, body);
  }

  delete(id: Number) {
    return this.api.delete('/admin/user/' + id);
  }

  setPassword(body: any) {
    return this.api.put('/admin/auth/change-password', body);
  }

  changePassword(body: any) {
    return this.api.put('/admin/auth/change-password-from-panel', body);
  }

  adminChangeCompanyUserPassword(body: any) {
    return this.api.put('/admin/company/user/auth/change-password-from-panel', body);
  }

  changeCompanyUserPassword(body: any) {
    return this.api.put('/company/auth/change-password-from-panel', body);
  }

  getQRCode(token: string) {
    return this.api.get('/admin/user/' + token + '/qrcode');
  }

  verifyCode(userId: any, body: any) {
    return this.api.put('/admin/user/' + userId + '/verify', body);
  }

  assignCompanies(body: any) {
    return this.api.post('/admin/user/assign/company', body);
  }

  removeAssignedCompany(body: any) {
    return this.api.put('/admin/user/unassign/company', body);
  }
}
