import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../../../../../core/services/auth/auth.service';
import { CompanyService } from './../../../../../core/services/company.service';
import { EventsService } from './../../../../../core/services/events.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss']
})
export class AddDepartmentComponent implements OnInit {
  departmentForm: FormGroup;
  companyId!: number;
  locations: any;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private companyService: CompanyService,
    private auth: AuthService,
    private toasterService: ToastrService
  ) {
    this.departmentForm = formBuilder.group({
      name: [null, Validators.required],
      locations: [[], Validators.required]
    });
  }

  ngOnInit() {}

  submitForm(e: any, v: any) {
    e.preventDefault();
    for (let c in this.departmentForm.controls) {
      this.departmentForm.controls[c].markAsTouched();
    }

    if (!this.departmentForm.valid) {
      return false;
    }
    v.company_id = this.companyId; // Assign company ID
    this.eventsService.broadcast('loader:show');
    this.companyService.addDepartment(v).subscribe({
      next: res => {
        this.eventsService.broadcast('loader:hide');
        this.eventsService.broadcast('companyLocation:refresh');
        this.eventsService.broadcast('departments:refresh');
        this.auth.accessLog({
          module_id: this.auth.module_ids.company,
          action: 'company_department_add',
          requested_url: '',
          method_type: 'post'
        }).subscribe();
        this.toasterService.success('Department added successfully.', 'Success');
        this.bsModalRef.hide();
      },
      error: err => {
        if (err && err.error) {
          this.toasterService.error(err.error, 'Error');
          if (err.error === 'token_expired') {
            this.bsModalRef.hide();
          }
        } else {
          this.toasterService.error('Something went wrong. We are looking into it.', 'Error');
        }
      }
    });
    return true;
  }
}
