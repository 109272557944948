<!-- START Sidebar (left)-->
<div class="overflow-auto">
  <!-- class="sidebar" -->
  <div class="area"></div>
  <nav class="main-menu" sidebar-anyclick-close="">

    <!-- START sidebar nav-->
    <ul class="nav">

      <!-- START user info-->
      <!-- <li class="has-user-block">
              <app-userblock></app-userblock>
          </li> -->
      <!-- END user info-->
      <!-- id="drop"  -->
      <li *ngFor='let item of menuItems' [ngClass]="{'nav-heading': item.heading}" [routerLinkActive]="['active']">
        <!-- menu heading -->
        <!-- <span *ngIf="item.heading">{{(item.translate | translate) || item.text}}</span> -->
        <!-- external links -->
        <a *ngIf="!item.heading && !item.submenu && item.elink" [attr.target]="item.target" [attr.href]="item.elink"
          title="{{item.text}}" class="d-flex align-items-center">
          <span class="pull-right" *ngIf="item.alert"
            [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
          <em class="{{item.icon}} icon-cl" *ngIf="item.icon"></em>
          <span class="text-padding-last">{{(item.translate | translate) || item.text}}</span>
        </a>
        <!-- single menu item -->
        <a *ngIf="!item.heading && !item.submenu && !item.elink" [routerLink]="item.link" [attr.route]="item.link"
          title="{{item.text}}" (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)"
          class="d-flex align-items-center">
          <span class="pull-right" *ngIf="item.alert"
            [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
          <em class="{{item.icon}} icon-cl" *ngIf="item.icon"></em>
          <span class="text-padding-last">{{(item.translate | translate) || item.text}}</span>
        </a>
        <!-- has submenu -->

        <a *ngIf="!item.heading && item.submenu" title="{{item.text}}" (mouseenter)="toggleSubmenuHover($event)"
          class="d-flex align-items-center">
          <!-- (click)="toggleSubmenuClick($event)" -->
          <!-- (mouseenter)="toggleSubmenuHover($event)" -->
          <span class="pull-right" *ngIf="item.alert"
            [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
          <em class="{{item.icon}} icon-cl" *ngIf="item.icon"></em>
          <span class="text-padding-last">{{(item.translate | translate) || item.text}}</span>
          <span class="fa fa-caret-down"></span>

        </a>
        <!-- SUBLEVEL -->
        <!-- <div id="drop_down">
                  <ol>
                      <li> -->
        <ul *ngIf="item.submenu" class="nav sidebar-subnav sub_menu_stl submenulist" [routerLinkActive]="['opening']">
          <!-- <li class="sidebar-subnav-header">{{(item.translate | translate) || item.text}}</li> -->
          <li *ngFor='let subitem of item.submenu' [routerLinkActive]="['active']">
            <!-- sublevel: external links -->
            <a *ngIf="!subitem.heading && !subitem.submenu && subitem.elink" [attr.target]="subitem.target"
              [attr.href]="subitem.elink" title="{{subitem.text}}">
              <span class="pull-right" *ngIf="subitem.alert"
                [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
              <em class="{{subitem.icon}} icon-cl" *ngIf="subitem.icon"></em>
              <span class="text-padding-last sub_menu sub_menu_stl_hr" style="padding: 10px;">{{(subitem.translate |
                translate) || subitem.text}}</span>
            </a>
            <!-- sublevel: single menu item  -->
            <a class="sub_menu_stl_hr" id="dp" *ngIf="!subitem.submenu && !subitem.elink" [routerLink]="subitem.link"
              [attr.route]="subitem.link" title="{{subitem.text}}" class="d-flex align-items-center">
              <span class="pull-right" *ngIf="subitem.alert"
                [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
              <em class="{{subitem.icon}} icon-sb" *ngIf="subitem.icon"></em>
              <span> {{(subitem.translate | translate) || subitem.text}}</span>
            </a>
            <!-- sublevel: has submenu -->
            <!-- <a *ngIf="subitem.submenu" title="{{subitem.text}}"
                                      (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
                                          <span class="pull-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                                          <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                                          <span>{{(subitem.translate | translate) || subitem.text}}</span>
                                  </a> -->
            <!-- SUBLEVEL 2 -->
            <ul *ngIf="subitem.submenu" class="nav sidebar-subnav level2" [routerLinkActive]="['opening']">
              <li *ngFor='let subitem2 of subitem.submenu' [routerLinkActive]="['active']">
                <!-- sublevel 2: single menu item  -->
                <a *ngIf="!subitem2.submenu" [routerLink]="subitem2.link" [attr.route]="subitem2.link"
                  title="{{subitem2.text}}" class="d-flex align-items-center">
                  <span class="pull-right" *ngIf="subitem2.alert"
                    [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                  <em class="{{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                  <span>{{(subitem2.translate | translate) || subitem2.text}}</span>
                </a>
                <!-- sublevel2: has submenu -->
                <a *ngIf="subitem2.submenu" title="{{subitem2.text}}" (click)="toggleSubmenuClick($event)"
                  (mouseenter)="toggleSubmenuHover($event)" class="d-flex align-items-center">
                  <span class="pull-right" *ngIf="subitem2.alert"
                    [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                  <em class="{{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                  <span>{{(subitem2.translate | translate) || subitem2.text}}</span>
                </a>
                <!-- SUBLEVEL 3 -->
                <ul *ngIf="subitem2.submenu" class="nav sidebar-subnav level3" [routerLinkActive]="['opening']">
                  <li *ngFor='let subitem3 of subitem2.submenu' [routerLinkActive]="['active']">
                    <!-- sublevel 2: single menu item  -->
                    <a *ngIf="!subitem3.submenu" [routerLink]="subitem3.link" [attr.route]="subitem3.link"
                      title="{{subitem3.text}}" class="d-flex align-items-center">
                      <span class="pull-right" *ngIf="subitem3.alert"
                        [ngClass]="subitem3.label || 'label label-success'">{{subitem3.alert}}</span>
                      <em class="{{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                      <span>{{(subitem3.translate | translate) || subitem3.text}}</span>
                    </a>
                    <!-- sublevel3: has submenu -->
                    <a *ngIf="subitem3.submenu" title="{{subitem3.text}}" (click)="toggleSubmenuClick($event)"
                      (mouseenter)="toggleSubmenuHover($event)" class="d-flex align-items-center">
                      <span class="pull-right" *ngIf="subitem3.alert"
                        [ngClass]="subitem3.label || 'label label-success'">{{subitem3.alert}}</span>
                      <em class="{{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                      <span>{{(subitem3.translate | translate) || subitem3.text}}</span>
                    </a>
                    <!-- SUBLEVEL 4 -->
                    <ul *ngIf="subitem3.submenu" class="nav sidebar-subnav level3" [routerLinkActive]="['opening']">
                      <li *ngFor='let subitem4 of subitem3.submenu' [routerLinkActive]="['active']">
                        <!-- sublevel 2: single menu item  -->
                        <a *ngIf="!subitem4.submenu" [routerLink]="subitem4.link" [attr.route]="subitem4.link"
                          title="{{subitem4.text}}" class="d-flex align-items-center">
                          <span class="pull-right" *ngIf="subitem4.alert"
                            [ngClass]="subitem4.label || 'label label-success'">{{subitem4.alert}}</span>
                          <em class="{{subitem4.icon}}" *ngIf="subitem4.icon"></em>
                          <span>{{(subitem4.translate | translate) || subitem4.text}}</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              <li>
            </ul>
          <li>
        </ul>
        <!-- </li>
                  </ol>
              </div> -->
      </li>

    </ul>
    <!-- END sidebar nav-->

  </nav>
</div>
<!-- END Sidebar (left)-->
