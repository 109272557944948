import { TranslatorService } from './../../core/translator/translator.service';
import { ThemesService } from './../../core/themes/themes.service';
import { SettingsService } from './../../core/settings/settings.service';
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';

@Component({
  selector: 'app-offsidebar',
  templateUrl: './offsidebar.component.html',
  styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {
  currentTheme: any;
  selectedLanguage: string;
  clickEvent = "click.offsidebar";
  $doc: any = null;

  @HostBinding('class.is-open')
  isOpen = false;

  constructor(
    public settings: SettingsService,
    public themes: ThemesService,
    public translator: TranslatorService
  ) {
   // this.currentTheme = themes.getDefaultTheme();

    let test = localStorage.getItem('theme');
    if(test == "undefind"){
      this.currentTheme = themes.getDefaultTheme();
    }
    else{
      this.currentTheme = test;
    }

    this.selectedLanguage = this.getLangs()[0].code;
  }

  ngOnInit() {
    this.anyClickClose();
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  setTheme() {
    this.themes.injectStylesheet(this.currentTheme);
    localStorage.setItem('theme',this.currentTheme);
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value: any) {
    this.translator.useLanguage(value);
  }

  anyClickClose() {
    this.$doc = $(document).on(this.clickEvent, (e) => {
      if ($(e.target).parents('.navbar-right').length) {

      } else {
        if (!$(e.target).parents(".offsidebar").length) {
          this.settings.layout.offsidebarOpen = false;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.$doc) this.$doc.off(this.clickEvent);
  }
}
