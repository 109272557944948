import { NotificationService } from './../../core/services/notification.service';
import { SessionTimerService } from './../../core/services/session-timer.service';
import { ActiveSessionService } from './../../core/services/active-session.service';
import { UserblockService } from './../sidebar/userblock/userblock.service';
import { Router } from '@angular/router';
import { SettingsService } from './../../core/settings/settings.service';
import { MenuService } from './../../core/menu/menu.service';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  Input,
} from '@angular/core';
import { OffsidebarComponent } from '../offsidebar/offsidebar.component';
import { map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

var momentTz = require('moment-timezone');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  navCollapsed = true; // for horizontal layout

  isNavSearchVisible: boolean = false;

  @Input() sideBar: OffsidebarComponent;

  @HostListener('click')
  click() {
    this.sideBar.toggle();
  }

  notificationData: any[] = [];
  notificationCount: number = 0;
  dropdownOpen: boolean = false;

  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public router: Router,
    public cdRef: ChangeDetectorRef,
    public activeSessionService: ActiveSessionService,
    public sessionTimerService: SessionTimerService,
    public notificationService: NotificationService,
    public toasterService: ToastrService
  ) {
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    // this.settings.layout.isCollapsed = true;
    this.settings.layout.isCollapsed = false; // remove this line for isCollapsed trun on
    window.onload = () => {
      let account_type = localStorage.getItem('account_type');
      var prefix_name = account_type && account_type === 'company_user' ? 'company' : 'admin';
      this.sessionTimerService.startSessionTimer(prefix_name);
    };
    this.getNotifications();
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;
  }

  getNotifications() {
    let account_type = localStorage.getItem('account_type');
    var prefix_name = account_type && account_type === 'company_user' ? 'Company User' : 'admin';
    this.notificationService
      .get(prefix_name)
      .pipe(map((res) => res))
      .subscribe({
        next: (res: any) => {
          // res.data.forEach((element: any) => {

          //   let userTimezone = momentTz.tz.guess();
          //   element.sent_at = momentTz.tz(momentTz.utc(element.sent_at, 'YYYY-MM-DD HH:mm:ss'), userTimezone).format('MMMM Do h:mm a');
          // });

          this.notificationData = res.data;
          this.notificationCount = res.total;
        },
        error: (err: any) => {
          if (err) {
            if (err.error) {
              this.toasterService.error(err.error);
            } else {
              this.toasterService.error(
                'Something went wrong. We are looking into it.'
              );
            }
          }
        },
      });
  }

  isLast(notification: Notification): boolean {
    return this.notificationData.indexOf(notification) === this.notificationData.length - 1;
  }

  handleNotificationClick(notificationId: number) {
    const clickedNotification = this.notificationData.find(notification => notification.id === notificationId);
    this.notificationService.markRead(notificationId)
      .pipe(map((res) => res))
      .subscribe({
        next: (res: any) => {
          this.getNotifications();
          if (clickedNotification.data) {
            window.location.href = clickedNotification.data;
          }
        },
        error: (err: any) => {
          if (err) {
            if (err && err.error) {
              this.toasterService.error(err.error);
            } else {
              this.toasterService.error(
                'Something went wrong. We are looking into it.'
              );
            }
          }
        },
      });
  }

  markAllAsRead($event: Event, navigate: boolean) {
    $event.preventDefault();

    let account_type = localStorage.getItem('account_type');
    var prefix_name = account_type && account_type === 'company_user' ? 'Company User' : 'admin';
    this.notificationService.markAllRead(prefix_name)
      .pipe(map((res) => res))
      .subscribe({
        next: (res: any) => {
          this.getNotifications();
          this.toasterService.success('All notifications marked as read.');
        },
        error: (err: any) => {
          if (err) {
            if (err && err.error) {
              this.toasterService.error(err.error);
            } else {
              this.toasterService.error(
                'Something went wrong. We are looking into it.'
              );
            }
          }
        },
      });

    if (navigate) {
      this.goToNotificationsPage($event);
    }
  }

  goToNotificationsPage($event: Event) {
    $event.preventDefault();
    this.dropdownOpen = false;
    this.router.navigate(['/notifications']);
  }

  toggleUserBlock(event: { preventDefault: () => void }) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event: {
    stopPropagation(): unknown;
    preventDefault: () => void;
  }) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(false);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
  }

  toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  logout() {
    let active_session_data = localStorage.getItem('active_session_data');
    if (active_session_data) {
      var session_obj = JSON.parse(active_session_data);
      let account_type = localStorage.getItem('account_type');
      var prefix_name = account_type && account_type === 'company_user' ? 'company' : 'admin';
      this.activeSessionService
        .deleteActiveSessionData(session_obj, prefix_name)
        .subscribe({
          next: (res: any) => {
            this.sessionTimerService.stopSessionTimer();
          },
          error: (err: any) => {
          },
        });
      localStorage.removeItem('active_session_data');
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    let accountType = localStorage.getItem('account_type');
    this.menu.resetMenu();
    if (accountType && accountType === 'company_user') {
      localStorage.removeItem('account_type');
      this.router.navigate(['/company/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
