<div class="modal-header">
  <h4 class="modal-title pull-left">
    <i class="fa fa-lock" aria-hidden="true"></i> Change Password
  </h4>

  <button type="button" class="close pull-right btn p-0" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="changePasswordForm" (ngSubmit)="setPassword(changePasswordForm.valid)">
    <div class="form-group has-feedback position-relative mb-3">
      <input autofocus class="form-control" id="password" type="password" name="password" placeholder="Password"
        formControlName="password" required="" />
      <span class="fa fa-lock form-control-feedback position-absolute end-0 px-2 text-muted"></span>
      <span class="text-danger" *ngIf="
          (changePasswordForm.controls['password'].hasError('required') || (changePasswordForm.controls['password'].hasError('whitespace'))) &&
          (changePasswordForm.controls['password'].dirty ||
            changePasswordForm.controls['password'].touched)
        ">This field is required</span>
      <span class="text-danger" *ngIf="!(changePasswordForm.controls['password'].hasError('required') || (changePasswordForm.controls['password'].hasError('whitespace'))) &&
      changePasswordForm.controls['password'].dirty &&
      changePasswordForm.controls['password'].hasError('minlength')">Password must contain atleast 6 characters</span>
    </div>
    <div class="form-group has-feedback position-relative mb-4">
      <input class="form-control" id="confirm_password" type="password" name="confirm_password"
        placeholder="Confirm Password" formControlName="confirm_password" required="" Equalvalidate="password" />
      <span class="fa fa-lock form-control-feedback position-absolute end-0 px-2 text-muted"></span>
      <span class="text-danger" *ngIf="
          (changePasswordForm.controls['confirm_password'].hasError('required') || (changePasswordForm.controls['confirm_password'].hasError('whitespace'))) &&
          (changePasswordForm.controls['confirm_password'].dirty ||
            changePasswordForm.controls['confirm_password'].touched)
        ">This field is required</span>
      <span class="text-danger" *ngIf="!(changePasswordForm.controls['confirm_password'].hasError('required') || (changePasswordForm.controls['confirm_password'].hasError('whitespace'))) &&
          changePasswordForm.controls['confirm_password'].dirty &&
          changePasswordForm.errors?.['mismatch']
        ">
        Passwords do not match.
      </span>
    </div>
    <div class="clearfix"></div>
    <button class="btn btn-block btn-primary mt-lg" type="submit" [disabled]="loading">
      Update Password
    </button>
  </form>
</div>

<div class="modal-footer">
  <a class="pull-right btn btn-default" (click)="bsModalRef.hide()">Close</a>
</div>
