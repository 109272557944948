import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  userData: any;

  constructor(private authService: AuthService,
    private router: Router,
    private toasterService: ToastrService,
    private titleService: Title) {
  }

  /**
     * Auth-Guard Main function to validate user permission/login before the page visit
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // ToDo: check if user is login using jwt tokenNotExpired function
    if (!localStorage.getItem('user')) {
      // If user is not login redirect user to company login page.
      // Later we can be more smart and decide if needs to redirect to admin login.

      this.router.navigate(['/company/login'], { queryParams: { returnUrl: state.url } });
      this.toasterService.warning('You must be logged in to view this content.', 'First Login');
      return false;
    } else {

      // If user is logged in check if permission is set in route data.

      if (route.data['permission']) {

        // Check if user has the permission the permission required for the route.
        if (!this.authService.hasAccess(route.data['permission'])) {

          this.userData = JSON.parse(localStorage.getItem('user') || '{}');

          let data = {
            'user_id': this.userData.id,
            'user_type': this.userData.role.name,
            'action': 'route_access'
          }

          this.authService.accessLog(data).subscribe({
            next: (res: any) => {
            },
            error: (err) => {
            }
          });

          this.authService.companyAccessLog(data).subscribe({
            next: (res: any) => {
            },
            error: (err) => {
            }
          });

          this.toasterService.warning(`You do not have permission to view "${state.url}".`, 'Access Denied');

          // Redirect to dashboard if the route previous route is login, or it is page refresh, or it is company login
          if (this.router.url.startsWith('/login') || this.router.url === '/' || this.router.url.startsWith('/company/login')) {
            this.router.navigate(['dashboard']);
          }

          // otherwise return false that won't redirect to dashboard and user stays on the same page.
          return false;
        }
      }
    }

    // Set the title
    if (route.data['title']) {
      this.titleService.setTitle(route.data['title']);
    }

    return true;
  }

}
