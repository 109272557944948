import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OsService {
  getOperatingSystem(): { name: string; version: string } {
    const userAgent = window.navigator.userAgent;
    let OSName = 'Unknown OS';
    let OSVersion = '';

    if (
      userAgent.indexOf('iPhone') !== -1 ||
      userAgent.indexOf('iPad') !== -1
    ) {
      OSName = 'iOS';
      OSVersion = this.getIOSVersion(userAgent);
    } else if (userAgent.indexOf('Windows') !== -1) {
      OSName = 'Windows';
      OSVersion = this.getWindowsVersion(userAgent);
    } else if (userAgent.indexOf('Mac') !== -1) {
      OSName = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      OSName = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
      OSName = 'Android';
      OSVersion = this.getAndroidVersion(userAgent);
    }

    return { name: OSName, version: OSVersion };
  }

  getIOSVersion(userAgent: string): string {
    const match = userAgent.match(/iPhone(?:\s+OS)?\s+(\d+[\._]\d+)/);
    if (match && match.length > 1) {
      return match[1].replace('_', '.');
    }
    return '';
  }

  getWindowsVersion(userAgent: string): string {
    const windowsVersionRegex = /Windows NT (\d+\.\d+)/;
    const match = userAgent.match(windowsVersionRegex);
    if (match && match.length > 1) {
      return match[1];
    }
    return '';
  }

  getAndroidVersion(userAgent: string): string {
    const match = userAgent.match(/Android\s+(\d+(\.\d+)?)/);
    if (match && match.length > 1) {
      return match[1];
    }
    return '';
  }
}
