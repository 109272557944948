import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private url = environment.api_endpoint;

	constructor(public http: HttpClient) {
	}

	get(endpoint: string, params?: any, options?: any) {
		let getUrl = (endpoint.indexOf('http') > -1) ? endpoint : (this.url + endpoint);
		if (!options) {
			options = {};
		}

		// Support easy query params for GET requests
		let p = new HttpParams();
		if (params) {
			for (let k in params) {
				p = p.append(k, params[k]);
			}
			// Set the search field if we have params and don't already have
			// a search field set in options.
			options.params = p;
		}

		return this.http.get(getUrl, {params: p});
	}

	post(endpoint: string, body: any, options?: any) {
		if (!options) {
			options = {};
		}

		return this.http.post(this.url + endpoint, body, options);
	}

	put(endpoint: string, body: any, options?: any) {
		if (!options) {
			options = {};
		}

		return this.http.put(this.url + endpoint, body, options);
	}

	delete(endpoint: string, options?: any) {
		if (!options) {
			options = {};
		}

		return this.http.delete(this.url + endpoint, options);
	}

	patch(endpoint: string, body: any, options?: any) {
		if (!options) {
			options = {};
		}

		return this.http.put(this.url + endpoint, body, options);
	}

	// private catchErrors() {

	// 	return (res: Response) => {

	// 		if (res.status === 401 || res.status === 403) {
	// 			// console.log("Error_Token_Expired: redirecting to login.");
	// 			// this.events.publish('user:logout');
	// 		}
	// 		return observableThrowError(res);
	// 	};
	// }
}
