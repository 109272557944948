import { TranslatorService } from './translator/translator.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { EventsService } from './services/events.service';
import { ThemesService } from './themes/themes.service';
import { SettingsService } from './services/settings/settings.service';
import { BroadcastService } from './services/broadcast.service';



@NgModule({
  declarations: [
    LoaderComponent
  ],
  providers: [
    EventsService,
    BroadcastService,
    TranslatorService,
    SettingsService,
		ThemesService
  ],
  imports: [
    CommonModule,


  ],
  exports: [
    LoaderComponent
  ]
})
export class CoreModule { }
