import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  account_type;
  prefix;
  constructor(public api: ApiService) {
    this.account_type = localStorage.getItem('account_type');
    this.prefix = this.account_type == 'company_user' ? '/company' : '/admin'
  }

  get(user_type: any) {
    return this.api.get(this.prefix + '/notifications');
  }

  getAll() {
    return this.api.get(this.prefix + '/notifications-all');
  }

  markRead(id: Number) {
    return this.api.post(this.prefix + '/notifications/mark-read', { 'id': id });
  }

  markAllRead(user_type: any) {
    return this.api.post(this.prefix + '/notifications/mark-read-all', { 'user_type': user_type });
  }

  getAllUserNotificationsList() {
    return this.api.get(this.prefix + '/get-user-notifications-list');
  }

  updateNotificationStatus(body: Object) {
    return this.api.post(this.prefix + '/update-notification-status', body);
  }
}
