import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// from
@Injectable()
export class BroadcastService {
  private eventSubject = new Subject<any>();

  broadcast(event: any, args: any) {
    this.eventSubject.next({event, args});
  }

  getEvent() {
    return this.eventSubject.asObservable();
  }
}
