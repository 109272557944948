import { AbstractControl } from '@angular/forms';

export function noBlankSpaceValidator(control: AbstractControl) {
  if (control.value && control.value.trim().length === 0) {
    return { blankSpace: true };
  }
  return null;
}

export function customEmailValidator(control: AbstractControl) {
  // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (control.value && !emailPattern.test(control.value)) {
    return { invalidEmail: true };
  }
  return null;
}