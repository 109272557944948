
const Dashboard = {
	text: 'Dashboard',
	link: '/company/dashboard',
	icon: 'icon-home'
};

const CompanyLocations = {
	text: 'Company Locations',
	link: '/company/locations',
	icon: 'icon-people',
	permission: 'company.location.list'
};

const CompanyUsers = {
	text: 'Users',
	link: '/company/users',
	icon: 'icon-people',
	permission: 'user.list'
};

const Scans = {
	text: 'Scans',
	link: '/company/scans/list',
	icon: 'fa fa-bug'
};

// const SearchPublishedScans = {
// 	text: 'Published Scans',
// 	link: '/company/search-published-scans',
// 	icon: 'fa fa-search',
// }

const Reports = {
	text: 'Reports',
	link: '/company/scans/reports',
	icon: 'fa fa-file-text-o'
};

const Settings = {
	text: 'Settings',
	link: '/company/settings',
	icon: 'fa fa-wrench'
};

const Integrations = {
  text: 'Integrations',
  link: '/company/integrations',
  icon: 'fa fa-solid fa-link',
  permission: 'user.list'
};

const headingMain = {
	text: 'Navigation',
	heading: true
};

export const company_menu = [
	headingMain,
	Dashboard,
	CompanyLocations,
	CompanyUsers,
	Scans,
	Reports,
	Settings,
  Integrations
];
