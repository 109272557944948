
import { AuthService } from './core/services/auth/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/services/auth/token.interceptor';
import { AuthGuardService } from './core/services/auth/auth-guard.service';
import { ChangePasswordModalComponent } from './routes/admin-modules/user/change-password-modal/change-password-modal.component';
import { AddDepartmentComponent } from './routes/admin-modules/company/company-edit/add-department/add-department.component';
import { EditDepartmentComponent } from './routes/admin-modules/company/company-edit/edit-department/edit-department.component';
import { MenuService } from './core/menu/menu.service';
import { company_menu } from './routes/company-menu';
import { admin_menu } from './routes/admin-menu';
import { SettingsService } from './core/settings/settings.service';
import { LoginComponent } from './routes/admin-components/login/login.component';
import { ForgotPasswordComponent } from './routes/admin-components/forgot-password/forgot-password.component';
import { PageNotFoundComponent } from './routes/admin-components/page-not-found/page-not-found.component';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordModalComponent,
    AddDepartmentComponent,
    EditDepartmentComponent,
    LoginComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      progressBar: true,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,

    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    RouterModule,
    LayoutModule,
    SharedModule,
    // Ng2AutoCompleteModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthService,
    AuthGuardService,
    Title,
    ToastrService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public menuService: MenuService, settings: SettingsService) {
    let account_type = localStorage.getItem('account_type');
    if (account_type && account_type === 'company_user') {
      menuService.addMenu(company_menu);
    } else if (account_type && account_type === 'admin_user') {
      menuService.addMenu(admin_menu);
    }
    let user = localStorage.getItem('user');
    if (user) {
      let parsedData = JSON.parse(user);
      settings.setUserSetting('name', parsedData.first_name);
    }
  }
}
