import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[autoScroll]'
})
export class AutoScrollDirective implements AfterViewInit, OnDestroy {
  private scrollContainer: HTMLElement;
  private mutationObserver: MutationObserver;

  constructor(private el: ElementRef) {
    this.scrollContainer = el.nativeElement;
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    // Observing childList mutations to trigger scroll to bottom
    this.mutationObserver = new MutationObserver(() => {
      this.scrollToBottom();
    });
    const config = { childList: true };
    this.mutationObserver.observe(this.scrollContainer, config);
  }

  ngOnDestroy() {
    this.mutationObserver.disconnect();
  }

  private scrollToBottom() {
    this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
    console.log(this.scrollContainer.scrollTop);

  }
}
