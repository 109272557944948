import { ModuleIds } from './../../models/ModuleIds';
import { SettingsService } from './../settings/settings.service';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  module_ids: ModuleIds;

  constructor(private api: ApiService, private settings: SettingsService) {
    this.module_ids = settings.modules_ids;
  }

  login(body: Object) {
    return this.api.post('/admin/auth', body);
  }

  companyLogin(body: Object) {
    return this.api.post('/company/auth', body);
  }

  adminForgotPassword(body: any) {
    return this.api.post('/admin/auth/forgot-password', body);
  }

  adminResetAuth(body: any) {
    return this.api.post('/admin/auth/reset-auth', body);
  }

  adminCompanyUserResetAuth(body: any) {
    return this.api.post('/admin/company/user/auth/reset-auth', body);
  }

  companyResetAuth(body: any) {
    return this.api.post('/company/auth/reset-auth', body);
  }

  companyForgotPassword(body: any) {
    return this.api.post('/admin/company-user/forgot-password', body);
  }

  adminResetPassword(body: any) {
    return this.api.put('/admin/auth/reset-password', body);
  }

  companyResetPassword(body: any) {
    return this.api.put('/company/auth/reset-password', body);
  }

  accessLog(body: any) {
    let user = JSON.parse(localStorage.getItem('user')|| '{}');
    body.user_id = user.id;
    body.user_type = user.role.name;
    return this.api.post('/admin/log', body);
  }

  companyAccessLog(body: any) {
    return this.api.post('/company/log', body);
  }

  /**
     * Check if user has specific permission(s)
     * @param {string | string[]} permission
     * @returns {boolean}
     */
  hasAccess(permission: string | string[]): boolean {
    if (this.getUserFromLocalStorage()) {
      if (Array.isArray(permission)) {
        return this.getUserFromLocalStorage().permissions.some((p: any) => permission.includes(p));
      } else {
        return this.getUserFromLocalStorage().permissions.indexOf(permission) !== -1;
      }
    }
    return false;
  }

  /**
     * get User data from Local storage
     * @returns {User}
     */
  getUserFromLocalStorage() {
    if (localStorage.getItem('user') === null) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('user')|| '{}');
    }
  }

  unblockUser(body: any) {
    return this.api.post('/admin/user/unblock', body);
  }

  deleteUser(id: any) {
    return this.api.delete('/admin/user/' + id);
  }

  filterAssignedCompanies(companies: any) {
    let user = JSON.parse(localStorage.getItem('user')|| '{}');
    if (user.role.name !== 'admin') {
      let assignedCompanyIds = user.assigned_companies.map((element: any) => {
        if (element) {
          return element.id;
        } else {
          return null;
        }
      });
      for (var i = companies.length - 1; i >= 0; i--) {
        if (assignedCompanyIds.indexOf(companies[i].id) === -1) {
          companies.splice(i, 1);
        }
      }
    }
    return companies;
  }

  getAccessLogs(params: any) {
    return this.api.get('/admin/log', params);
  }

  getActionFilter() {
    return this.api.get('/admin/actions');
  }
}
