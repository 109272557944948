import { SessionTimerService } from 'src/app/core/services/session-timer.service';
import { Component, HostBinding, OnInit } from '@angular/core';
import { SettingsService } from './core/services/settings/settings.service';
import { Router } from '@angular/router';
import { IdlePromptComponent } from './shared/components/idle-prompt/idle-prompt.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActiveSessionService } from './core/services/active-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'scanview';

  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.layout.isFixed;
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.layout.isCollapsed;
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.layout.isBoxed;
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.layout.useFullLayout;
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.layout.hiddenFooter;
  }
  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.layout.horizontal;
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.layout.isFloat;
  }
  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.layout.offsidebarOpen;
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.layout.asideToggled;
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  timoutIdle = 1500000; // Display warning in 25 Mins
  timoutLogout = 300000; // Logout after 5 Mins (after warning is shown and no activty)
  // idleTimer;
  idleTimer: NodeJS.Timeout;
  logoutTimer: any;
  timeoutTimer: any;

  // constructor(public settings: SettingsService, public router: Router) { }
  bsModalRef: BsModalRef;
  constructor(
    public settings: SettingsService,
    public router: Router,
    public modalService: BsModalService,
    public activeSessionService: ActiveSessionService,
    public sessionTimerService: SessionTimerService
  ) {}

  ngOnInit() {
    $(document).on('click', '[href="#"]', (e) => e.preventDefault());
    $(document).on('click', () => {
      this.ResetTimeOutTimer();
    });

    $(document).on('keyup', () => {
      this.ResetTimeOutTimer();
    });

    this.StartIdleTimer();
  }

  // Start warning timer.
  // StartLogoutTimer() {
  StartIdleTimer() {
    this.idleTimer = setTimeout(() => {
      if (localStorage.getItem('access_token')) {
        // open a modal
        this.sessionExpiryPrompt();
        this.startLogoutTimer();
      }
    }, this.timoutIdle);
  }

  startLogoutTimer() {
    this.logoutTimer = setTimeout(() => {
      if (localStorage.getItem('access_token')) {
        let active_session_data = localStorage.getItem('active_session_data');
        if (active_session_data) {
          var session_obj = JSON.parse(active_session_data);
          let account_type = localStorage.getItem('account_type');
          var prefix_name = account_type && account_type === 'company_user' ? 'company' : 'admin';
          this.activeSessionService
            .deleteActiveSessionData(session_obj, prefix_name)
            .subscribe({
              next: (res: any) => {
                this.sessionTimerService.stopSessionTimer();
              },
              error: (err: any) => {
              },
            });
          localStorage.removeItem('active_session_data');
        }
        this.modalService.hide(1);
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        let account_type = localStorage.getItem('account_type');
        localStorage.removeItem('account_type');
        if (account_type && account_type === 'company_user') {
          this.router.navigate(['/company/login']);
        } else {
          this.router.navigate(['/login']);
        }
      }
    }, this.timoutLogout);
  }

  // Reset timers.
  ResetTimeOutTimer() {
    clearTimeout(this.idleTimer);
    clearTimeout(this.logoutTimer);
    // this.StartLogoutTimer();
    this.StartIdleTimer();
  }

  sessionExpiryPrompt() {
    this.bsModalRef = this.modalService.show(IdlePromptComponent, {
      class: 'modal-sm',
    });
  }
}
