import { UserblockService } from './userblock.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})

export class UserblockComponent implements OnInit {
	user: any;
	constructor(public userblockService: UserblockService) {
		let user = localStorage.getItem('user');
		let parsedData;
		let name = '';
		if (user) {
			parsedData = JSON.parse(user);
			name = parsedData.first_name;
		} else {
			name = '';
		}
		if (parsedData) {
			this.user = {
				picture: 'https://images.vexels.com/media/users/3/135118/isolated/preview/676bf0e9f3c16649cd7f426c6dcd755a-flat-user-sign-with-round-background-by-vexels.png',
				name: name,
				role: parsedData.role
			};

			if(parsedData.company) {
				this.user.company = parsedData.company;
			}
		}
	}

	ngOnInit() {
	}

	userBlockIsVisible() {
		return this.userblockService.getVisibility();
	}

}
