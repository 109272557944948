
const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard',
  icon: 'fa fa-home'
};

const AccessLogs = {
  text: 'Access Logs',
  link: '/access-logs',
  icon: 'fa fa-search',
  permission: 'access_logs.read'
};

const Calendar = {
  text: 'Calendar',
  link: '/calendar',
  icon: 'fa fa-calendar'
};

const Users = {
  text: 'Users',
  link: '/users',
  icon: 'fa fa-users',
  permission: 'users.list'
};

const Companies = {
  text: 'Clients',
  link: '/companies',
  icon: 'fa fa-building',
  permission: 'company.add'
};

const CompanyUsers = {
  text: 'Client Users',
  link: '/company-users',
  icon: 'fa fa-users',
  permission: 'companyUser.list'
};

const PartnersManagement = {
  text: 'Partners Management',
  link: '/partners-management',
  icon: 'fa fa-handshake-o',
  permission: 'users.list'
};

const ScheduleScan = {
  icon: 'fa fa-calendar',
  text: 'Schedule Scan',
  link: '/schedule-scan',
  permission: 'scan.upload'
};

const GraphVisualization = {
  icon: 'fa fa-pie-chart',
  text: 'Graph Visualization',
  link: '/graph-visualization',
  permission: 'scan.listing'
};

const MasterPlugins = {
  icon: 'fa fa-tasks',
  text: 'Manage Master Plugins',
  link: '/master-plugin',
  permission: 'scan.master_plugins'
};

const PublishedScans = {
  text: 'Scans',
  icon: 'fa fa-file-text-o',
  permission: 'scan.listing',
  submenu: [
    {
      icon: 'fa fa-upload',
      text: 'Upload Scan Data',
      link: '/published-scan/upload',
      permission: 'scan.upload'
    },
    {
      icon: 'fa fa-file-text',
      text: 'Published Scans',
      link: '/published-scan/list',
      permission: 'scan.listing'
    },
    {
      icon: 'fa fa-search',
      text: 'Search Scan Data',
      link: '/published-scan/search',
      permission: 'scan.listing'
    },
    {
      icon: 'fa fa-database',
      text: 'Vulnerability Override Data',
      link: '/published-scan/vulnerability-override-data',
      permission: 'scan.master_plugins'
    },
    {
      icon: 'fa fa-file-text-o',
      text: 'Vulnerability Exclude',
      link: '/published-scan/vulnerability-exclude',
      permission: 'scan.master_plugins'
    },
    {
      icon: 'fa fa-tasks',
      text: 'Manage Exceptions',
      link: '/published-scan/manage-exceptions',
      permission: 'scan.master_plugins'
    },
    {
      icon: 'fa fa-map',
      text: 'Mappings',
      link: '/published-scan/mappings',
      permission: 'scan.mappings'
    },
    {
      icon: 'fa fa-th-list',
      text: 'Vulnerability to Exception Mapping',
      link: '/published-scan/scan',
      permission: 'scan.mappings'
    }

  ]
};

const Reports = {
  text: 'Reports',
  icon: 'fa fa-file-text-o',
  permission: 'reports.list',
  submenu: [
    {
      icon: 'fa fa-file-text-o',
      text: 'Manage Reports',
      link: '/reports/list',
      permission: 'reports.list'
    },
    {
      text: 'Editable Contents',
      link: '/report-editable-contents',
      icon: 'fa fa-file-text-o',
      permission: 'users.list'
    },
    {
      text: 'Page Builder',
      link: '/report-pages',
      icon: 'fa fa-file-text-o',
      permission: 'users.list'
    }
  ]
};

const Settings = {
  text: 'Settings',
  link: '/settings',
  icon: 'fa fa-solid fa-gear',
  // permission: 'reports.list'
};

const Integrations = {
  text: 'Integrations',
  link: '/integrations',
  icon: 'fa fa-solid fa-link',
  permission: 'users.list'
};

const headingMain = {
  text: 'Navigation',
  heading: true
};

export const admin_menu = [
  headingMain,
  Dashboard,
  Calendar,
  Users,
  Companies,
  CompanyUsers,
  PartnersManagement,
  ScheduleScan,
  MasterPlugins,
  PublishedScans,
  Reports,
  GraphVisualization,
  AccessLogs,
  Settings,
  Integrations
];
